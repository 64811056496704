:root {
    --brand-color: #0ea5e9;
    --dark-color: #0f172a;
    --mid-color: #cbd5e1;
    --light-color: #ffffff;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: var(--dark-color);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    color:var(--light-color)
  }
  
  /* Navigation.js */
  header {
    background-color: var(--brand-color);
    padding: 1rem;
    font-size: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  header h1 {
    color: var(--dark-color);
    font-size: 3rem;
    margin-right: 1rem;
  }
  
  header p {
    color: var(--light-color);
    font-size: 1.5rem;
    margin-left: 1.5rem;
    border-bottom: 1px solid var(--light-color);
    font-weight: bolder;
  }

  .header-icons{
    margin-left: 1.5rem;
    display: flex;
    align-items: center
  }

  .header-icons a {
    margin-left: 1.5rem;
    color: var(--light-color);
  }

  .header-icons a:hover {
    color: var(--dark-color);
  }
  
  /* About */
  .title {
    font-size: 2.5rem;
    color: yellow;
    font-weight: bolder;
    margin: 3rem 0;
    text-align: center;
    text-shadow: 2px 3px 4px #000000;
  }
  .about-img {
    width: 90%;
    max-width: 500px;
    min-width: 200px;
  }
  .about-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    justify-content: center;
    margin: 2em 0;
    background-color: #cbd5e15c;
    padding: 2em;
  }
  
  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 2rem;
    color: #ffffff;
    width: 90%;
  }
  
  .about-text p {
    margin-bottom: 1rem;
  }
  
  
  /* SKills  */
  
  .skill-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    margin: 1em 0;
  }
  
  .skill {
    background-color: var(--brand-color);
    padding: 1em;
    border-radius: 10px;
    color: var(--light-color);
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
    text-shadow: 2px 3px 4px #000000;
  }
  
  .skill:hover {
    box-shadow: 0px 2px 10px #f7f8f5;
  }
  
  /* Projects */
  .project-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .project {
    border-radius: 15px;
    padding: 1em;
    margin: 0 0.5rem;
    background-color: rgba(249, 249, 249, 0.445);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .project:hover {
    box-shadow: 0px 2px 10px #f0eaea;
  }
  
  .project-img {
    width: 100%;
    max-width: 200px;
    min-width: 100px;
    object-fit: cover;
  }
  
  .project-title {
    font-size: 1.5rem;
    color: var(--light-color);
    font-weight: bolder;
    text-align: center;
    text-shadow: 2px 3px 4px #000000;
  }
  
  .project-link {
    background-color: var(--dark-color);
    text-decoration: none;
    padding: 0.5rem 2rem;
    margin: 1.3rem;
    border-radius: 10px;
    color: white;
  }
  .project-link:hover {
    box-shadow: 0px 2px 10px #000000;
    background-color: #ffffff;
    color: var(--dark-color);
  }
  
  /* Footer */
  footer {
    background-color: rgb(218, 216, 216);
    margin-top: 2rem;
    padding: 2rem;
    text-align: center;
  }
  
  .social-links {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  /* Contact */
  footer p {
    color: var(--brand-color);
    font-size: 1.1rem;
    font-weight: bolder;
  }
  
  footer a {
    color: var(--dark-color);
    text-decoration: none;
    font-size: 1.2rem;
    font-size: 1.7rem;
  }
  
  footer a:hover {
    color: var(--brand-color);
    text-decoration: underline;
  }

  .welcome-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    color: #ffffff;
    width: 90%;
}

.welcome-image-container {
    margin-right: 2rem;
    margin-top: 5rem;
    margin-left: 2rem;
}

.welcome-image-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.welcome-image {
    height: 200px;
}

  
.welcome-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 40px;
  }
  
  .welcome-text {
    margin-bottom: 1rem;
    padding: 40px 20px px 20px;
  }
  
  .welcome-button {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    padding: 0.8rem 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .welcome-button:hover {
    background-color: #0069d9;
  }

  /* Presentation */
/* Presentation */
.video-container {
  display: flex;
  justify-content: center; /* center the boxes horizontally */
  align-items: center;
  margin: 2rem 0;
}

.video-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 42%;
  border: 4px solid #ccc;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 4rem; /* add space around the boxes */
}

.video-box:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.video-info {
  text-align: center;
  margin-top: 1rem;
}

.video-info h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.video-info p {
  font-size: 1.2rem;
}

  


  
  
  
  